"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.createEscapedSimpleChar = exports.createSimpleChar = void 0;
function createSimpleChar(value) {
    if (value.length !== 1)
        throw new Error('value must be a char');
    return {
        codePoint: value.codePointAt(0) || 0,
        kind: 'simple',
        symbol: value,
        type: 'Char',
        value: value
    };
}
exports.createSimpleChar = createSimpleChar;
function createEscapedSimpleChar(value) {
    return __assign(__assign({}, createSimpleChar(value)), { escaped: true });
}
exports.createEscapedSimpleChar = createEscapedSimpleChar;
