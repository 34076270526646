"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
exports.__esModule = true;
exports.Chars = void 0;
var nbsp = String.fromCharCode(160);
var whitespace = (" \t\r\n" + nbsp).split('');
var digits = '0123456789'.split('');
var underscore = '_';
var basicLowercase = 'abcdefghijklmnopqrstuvwxyz'.split('');
var basicUppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
var basicAlpha = __spreadArray(__spreadArray(__spreadArray([], __read(basicLowercase)), __read(basicUppercase)), [underscore]);
var basicSpecial = '~`!@#$%^&*()-=+<,>.?/[]{}|\\:;"\''.split('');
var extendedLowercase = 'àáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿ'.split('');
var extendedUppercase = 'ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞß'.split('');
var shy = String.fromCharCode(173);
var extendedSpecial = ("\u00A1\u00A2\u00A3\u00A4\u00A5\u00A6\u00A7\u00A8\u00A9\u00AA\u00AB\u00AC\u00AE\u00AF\u00B0\u00B1\u00B2\u00B3\u00B4\u00B5\u00B6\u00B7\u00B8\u00B9\u00BA\u00BB\u00BC\u00BD\u00BE\u00BF\u00D7\u00F7" + shy).split('');
// Special Windows-1252 display characters in the extended ASCII range
// https://www.ascii-code.com/#extendedASCIIDescription
var windows1252Special = '€‚ƒ„…†‡ˆ‰Š‹ŒŽ‘’“”•–—˜™š›œžŸ'.split('');
var extended = __spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read(extendedLowercase)), __read(extendedUppercase)), __read(extendedSpecial)), __read(windows1252Special));
var all = __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read(whitespace)), __read(digits)), __read(basicAlpha)), __read(basicSpecial)), __read(extended));
exports.Chars = {
    all: all,
    basicAlpha: basicAlpha,
    basicSpecial: basicSpecial,
    digits: digits,
    extended: extended,
    whitespace: whitespace
};
