"use strict";
exports.__esModule = true;
exports.isSimpleQuantifier = exports.isSimpleChar = exports.isRepetition = exports.isRangeQuantifier = exports.isMetaChar = exports.isNumericBackreference = exports.isNamedBackreference = exports.isGroup = exports.isDisjunction = exports.isClassRange = exports.isCharacterClass = exports.isChar = exports.isCapturingGroup = exports.isBackreference = exports.isAssertion = exports.isAlternative = void 0;
function isAlternative(node) {
    return node.type === 'Alternative';
}
exports.isAlternative = isAlternative;
function isAssertion(node) {
    return node.type === 'Assertion';
}
exports.isAssertion = isAssertion;
function isBackreference(node) {
    return node.type === 'Backreference';
}
exports.isBackreference = isBackreference;
function isCapturingGroup(node) {
    return node.capturing;
}
exports.isCapturingGroup = isCapturingGroup;
function isChar(node) {
    return node.type === 'Char';
}
exports.isChar = isChar;
function isCharacterClass(node) {
    return node.type === 'CharacterClass';
}
exports.isCharacterClass = isCharacterClass;
function isClassRange(node) {
    return node.type === 'ClassRange';
}
exports.isClassRange = isClassRange;
function isDisjunction(node) {
    return node.type === 'Disjunction';
}
exports.isDisjunction = isDisjunction;
function isGroup(node) {
    return node.type === 'Group';
}
exports.isGroup = isGroup;
function isNamedBackreference(node) {
    return node.kind === 'name';
}
exports.isNamedBackreference = isNamedBackreference;
function isNumericBackreference(node) {
    return node.kind === 'number';
}
exports.isNumericBackreference = isNumericBackreference;
function isMetaChar(char) {
    return char.kind === 'meta';
}
exports.isMetaChar = isMetaChar;
function isRangeQuantifier(quantifier) {
    return quantifier.kind === 'Range';
}
exports.isRangeQuantifier = isRangeQuantifier;
function isRepetition(node) {
    return node.type === 'Repetition';
}
exports.isRepetition = isRepetition;
function isSimpleChar(char) {
    return char.kind === 'simple';
}
exports.isSimpleChar = isSimpleChar;
function isSimpleQuantifier(quantifier) {
    return ['+', '*', '?'].includes(quantifier.kind);
}
exports.isSimpleQuantifier = isSimpleQuantifier;
