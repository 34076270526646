"use strict";
exports.__esModule = true;
exports.fill = void 0;
/**
 * Generate an array full of sequential integers.
 * @param start The lowest integer in the array, inclusive
 * @param end The highest integer in the array, inclusive
 * @returns An array containing start, end, and all the integers in between
 */
function fill(start, end) {
    var result = new Array(1 + end - start);
    for (var i = start; i <= end; i++) {
        result[i - start] = i;
    }
    return result;
}
exports.fill = fill;
