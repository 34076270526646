"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
exports.__esModule = true;
var Guards = __importStar(require("../types/regexp-tree-guards"));
/* istanbul ignore next */
function assertNever(x) {
    throw new Error("Unexpected quantifier kind: " + x);
}
function getNumOccurrences(quantifier) {
    if (quantifier.kind === '?') {
        return [0, 1];
    }
    else if (quantifier.kind === '+') {
        return [1, undefined];
    }
    else if (quantifier.kind === '*') {
        return [0, undefined];
    }
    /* istanbul ignore next */
    return assertNever(quantifier.kind);
}
/**
 * Convert the ?, +, and * quantifiers to their equivalent ranges of
 * {0,1}, {1,}, and {0,} respectively
 */
var simpleQuantifierToRangeQuantifierTransform = {
    Quantifier: function (quantifierPath) {
        var quantifier = quantifierPath.node;
        if (!Guards.isSimpleQuantifier(quantifier)) {
            return;
        }
        var _a = __read(getNumOccurrences(quantifier), 2), minOccurrences = _a[0], maxOccurrences = _a[1];
        var equivalentRangeQuantifier = {
            from: minOccurrences,
            greedy: quantifier.greedy,
            kind: 'Range',
            to: maxOccurrences,
            type: 'Quantifier'
        };
        quantifierPath.replace(equivalentRangeQuantifier);
    }
};
exports["default"] = simpleQuantifierToRangeQuantifierTransform;
