"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
exports.__esModule = true;
var constants_1 = require("../constants");
var Guards = __importStar(require("../types/regexp-tree-guards"));
var utils_1 = require("./utils");
/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["parentNode"] }] */
var replacer = {
    Alternative: function (parentNode, replacement, child) {
        var iChild = parentNode.expressions.indexOf(child);
        if (iChild > -1) {
            parentNode.expressions[iChild] = replacement;
        }
    },
    CharacterClass: function (parentNode, replacement, child) {
        var _a;
        var iChild = parentNode.expressions.indexOf(child);
        if (iChild > -1) {
            (_a = parentNode.expressions).splice.apply(_a, __spreadArray([iChild, 1], __read(replacement.expressions)));
        }
    },
    Disjunction: function (parentNode, replacement, child) {
        if (parentNode.left === child) {
            parentNode.left = replacement;
        }
        else if (parentNode.right === child) {
            parentNode.right = replacement;
        }
    },
    Group: function (parentNode, replacement) {
        parentNode.expression = replacement;
    },
    RegExp: function (parentNode, replacement) {
        parentNode.body = replacement;
    },
    Repetition: function (parentNode, replacement) {
        parentNode.expression = replacement;
    }
};
var optionsAlpha = constants_1.Chars.basicAlpha.map(utils_1.createSimpleChar);
var optionsDigit = constants_1.Chars.digits.map(utils_1.createSimpleChar);
var optionsWhitespace = constants_1.Chars.whitespace.map(utils_1.createSimpleChar);
var needEscape = [']', '-', '\\'];
var noEscape = constants_1.Chars.basicSpecial.filter(function (c) { return !needEscape.includes(c); });
var optionsOther = __spreadArray(__spreadArray([], __read(noEscape.map(utils_1.createSimpleChar))), __read(needEscape.map(utils_1.createEscapedSimpleChar)));
var optionsExtended = constants_1.Chars.extended.map(utils_1.createSimpleChar);
function getMetaCharExpressions(metaChar, regExpFlags) {
    switch (metaChar.value) {
        case '.': {
            var optionsNewLine = utils_1.createSimpleChar('\n');
            var optionsDotAll = regExpFlags.includes('s') ? [optionsNewLine] : [];
            var whitespaceNoBreaks = constants_1.Chars.whitespace.filter(function (c) { return !'\r\n'.includes(c); });
            var optionsWhitespaceNoBreak = whitespaceNoBreaks.map(utils_1.createSimpleChar);
            return __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read(optionsAlpha)), __read(optionsDigit)), __read(optionsWhitespaceNoBreak)), __read(optionsOther)), __read(optionsExtended)), __read(optionsDotAll));
        }
        case '\\w':
            return __spreadArray(__spreadArray([], __read(optionsAlpha)), __read(optionsDigit));
        case '\\W':
            return __spreadArray(__spreadArray(__spreadArray([], __read(optionsWhitespace)), __read(optionsOther)), __read(optionsExtended));
        case '\\d':
            return optionsDigit;
        case '\\D':
            return __spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read(optionsAlpha)), __read(optionsWhitespace)), __read(optionsOther)), __read(optionsExtended));
        case '\\s':
            return optionsWhitespace;
        case '\\S':
            return __spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read(optionsAlpha)), __read(optionsDigit)), __read(optionsOther)), __read(optionsExtended));
        default:
            return [];
    }
}
var metaToCharClassTransform = {
    flags: '',
    init: function (ast) {
        this.flags = ast.flags;
    },
    Char: function (charPath) {
        var char = charPath.node, parent = charPath.parent, parentPath = charPath.parentPath;
        if (!parent || !parentPath || !replacer[parent.type]) {
            return;
        }
        if (!Guards.isMetaChar(char)) {
            return;
        }
        var charClassExpressions = getMetaCharExpressions(char, this.flags);
        if (!charClassExpressions.length) {
            return;
        }
        var characterClass = {
            expressions: charClassExpressions,
            type: 'CharacterClass'
        };
        var replaceParent = replacer[parent.type];
        replaceParent(parentPath.node, characterClass, char);
    }
};
exports["default"] = metaToCharClassTransform;
